import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import {Helmet} from "react-helmet";

import Header from './header'
import Footer from './footer'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
          <Helmet defaultTitle={"KazikKoduje"} titleTemplate={`%s | KazikKoduje`}>
                  <body>
                  <script>{
                      `window.fbAsyncInit = function() {
                      FB.init({
                          appId      : '393041694503435',
                          xfbml      : true,
                          version    : 'v3.2'
                      })
                      FB.AppEvents.logPageView();
                  };

                      (function(d, s, id){
                      var js, fjs = d.getElementsByTagName(s)[0];
                      if (d.getElementById(id)) {return;}
                      js = d.createElement(s); js.id = id;
                      js.src = "https://connect.facebook.net/en_US/sdk.js";
                      fjs.parentNode.insertBefore(js, fjs);
                  }(document, 'script', 'facebook-jssdk'));`
                  }
                  </script>
                  </body>
          </Helmet>

        <Header siteTitle={data.site.siteMetadata.title} />
          <section className="container site">
              {children}
          </section>
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
